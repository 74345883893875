<template>
  <div class="main">
    <mtop />
    <div class="content">
      <div class="timg">
      </div>
      <div  :class="show?'cardh5':'card'">
        <div class="box">
          <div class="left">
            <div class="title">LOVE POTION</div>
            <div class="cont">
             Cupid token holders will be able to start applying their airdropped potion to their Cupid after the public sale is completed. The exact time will be announced on Twitter (@CML) and our Discord. 
Applying love potion to the Cupid in the digital wallet, your Cupid will perform a surprise change, which will retain the traits of the original Cupid and make Cupid wear brand new clothes. During this change, Cupid NFT will not be harmed. You will get a brand new Cupid in costume, and the original Cupid will be kept at the same time. There is no time limit for using potions.
            </div>
          </div>
          <div class="right">
            <div class="item">
              <img :src="url?url:base" alt="" class="isimg" />
              <div class="txt">SELECT YOUR CUPID</div>
                 <div class="changer" v-if="show">
          <div class="title">
            CLM Token ID   {{kid?"#"+kid:''}}   
          </div>
          <div class="select" @click="toshowList">
          <div class="txts">CUPID WITHOUT CLOTHES</div> 
          </div>
          <div class="red">
            Necessary
          </div>
        </div>
            </div>
              <div class="item cent">
              </div>
            <div class="item">
              <div class="txt">LOVE POTION</div>
              <div class="red">Cupid can only get one suit through each love potion</div>
            </div>
            <div class="item cent">
            </div>
            <div class="item">
              <img :src="newurls||baseimg" alt="" class="sh"  />
              <div class="txt">CUPID IN CLOTHES</div>
              <div class="btn" @click="cast" >APPLY THE</div>
            </div>
          </div>
        </div>
        <div class="changer" v-if="!show">
          <div class="title">
            CLM Token ID    {{kid?"#"+kid:''}}       
          </div>
          <div class="select" @click="toshowList">
          <div class="txts">CUPID WITHOUT CLOTHES</div> 
          </div>
          <div class="red">
            Necessary
          </div>
        </div>
      </div>
    </div>
    <mbottom />
    <van-dialog v-model="shows" :showConfirmButton="false" >
        <div class="cast">
         
          <div class="zzz">
            Mint...
          </div>
        </div>
    </van-dialog>
    <van-dialog v-model="showsuc" :showConfirmButton="false" >
        <div class="cast">
              
        </div>
        <div class="castbomt">
          <div class="btn" @click="MintSuc">
            Complete
          </div>
        </div>
    </van-dialog>
    <van-popup v-model="showlist" round :close-on-click-overlay="false" closeable  >
      <div class="popup" :class="show?'popuph5':''">
        <div class="title"></div>
        <div class="boxs">
          <div class="item" v-for="(item,key) in list" :key='key' @click="tochanger(item,key)">
            <img :src="item" alt="" class="sbg" v-if="item">
           
            
            <div class="zzz" v-if="!item">Loading</div>
                 <div class="bh">CUPID #{{key}}</div>
          </div>
        </div>   
          <div class="bottom">
        <van-button
          type="primary"
          @click="closes"
          class="btn"
          :loading-text="$t('m.tjiaolog')"
        >
         Confirm</van-button
        >
        </div>  
      </div>
    </van-popup>
    
  </div>
</template>

<script>
import { walletOfOwner,tokenURI,getmyLove,getToken,isApprovedForAll,setApprovalForAll,mutateApeWithSerum} from "@/api/Public";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
  data() {
    return {
      shows:false,
      showsuc:false,
      show:false,      
      showlist:false,
      list:{},
      url:'',
      base:'',
      basedata:[],
      kid:'',
      can:false,
      love:[],
      newurl:'',
      newurls:'',
      baseimg:''




      
    };
  },
    created(){
  },
  components: {
    mtop,
    mbottom,
  },
       mounted() {
    if (this._isMobile()) {
      this.show=true;
    } else {
      this.show=this.$store.getters.phone;
    }    
  },
  watch: {
      '$store.getters.phone': function (val) {
      this.show = val
    },
    deep: true,
    immediate: true,
   },
  methods:{
 
        _isMobile() {
   let flag = navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
 return flag;   
},
closes(){
  this.showlist=false
},
MintSuc(){
  this.showsuc=false
  this.newurls=this.newurl
  this.can=false
},
tochanger(e,i){
  this.url=e  
  this.kid=i
  isApprovedForAll(this.$store.getters.userWallet).then(res=>{
    if(res){
      if(this.kid) {
        this.showlist=false 
      this.can=true
      this.getmyLove()
      this.getToken(this.kid)
      }
    }else{
      setApprovalForAll(this.$store.getters.userWallet).then(rs=>{
          if(rs){            
            this.showlist=false 
          this.can=true
          this.getmyLove()
          this.getToken(this.kid)
          }
      })
    }
  })

},
toshowList(){
 var address=this.$store.getters.userWallet  
      if(!address){
        this.wallet()
        return
      }
   
      walletOfOwner(this.$store.getters.userWallet).then(res=>{
            // console.log("res",res);
            this.basedata=res
            if(res.length>0){this.showlist=true
              res.forEach((el) => {
                this.getUrl(el)
              }); 
              // this.list.length=res.length 
                // console.log(this.list);
            }
            })
},
getToken(e){
  if(e){
  getToken(e).then(res=>{
    if(res){
       let regs=new RegExp('https://ipfs.io/','g')
      var urls= res.replace(regs,'https://www.cupidlovemetaverse.com/');
      console.log('-----',urls);
      this.can=false 
         this.$axios({
            method:'GET',
                url:urls
            }).then((response) =>{ 
              this.shows=false
              let reg=new RegExp('ipfs://','g')
               var url= response.data.image.replace(reg,'https://www.cupidlovemetaverse.com/ipfs/');
               this.newurls=url
            this.$forceUpdate()
            })     
    }else{
      this.newurls=''
      this.can=true
    }
  })
  }
},
getmyLove(){
  getmyLove(this.$store.getters.userWallet).then(res=>{
    console.log('---------',res);
    this.love=res
  })
},
  getUrl(e){
    
      tokenURI(e).then(res=>{
               let regs=new RegExp('https://ipfs.io/','g')
      var urls= res.replace(regs,'https://www.cupidlovemetaverse.com/');
         this.$axios({
            method:'GET',
                url:urls
            }).then((response) =>{        
              // console.log(response.data);              
              let reg=new RegExp('ipfs://','g')
               var url= response.data.image.replace(reg,'https://www.cupidlovemetaverse.com/ipfs/');
                this.list[e]=url
             
            this.$forceUpdate()
            })
      
      })
    },
    cast(){
      if(this.can){
        if(this.love[0]){
      this.shows=true
      mutateApeWithSerum(this.kid,this.love[0],this.$store.getters.userWallet).then(res=>{
        if(res){
        getToken(this.kid).then(ur=>{
    if(ur){
             let regs=new RegExp('https://ipfs.io/','g')
      var urls= ur.replace(regs,'https://www.cupidlovemetaverse.com/');
      this.can=false
       this.$axios({
            method:'GET',
                url:urls
            }).then((response) =>{ 
              this.shows=false
              let reg=new RegExp('ipfs://','g')
               var url= response.data.image.replace(reg,'https://www.cupidlovemetaverse.com/ipfs/');
               this.newurl=url
               this.showsuc=true

            this.$forceUpdate()
            })
    }})
        }else{
           this.shows=false
        }
      }) 
        }else{
       this.$toast('You don\'t have any potions');
          }  
      }else{
         this.$toast('Please select a new Cupid');
      }
    },
  }
};
</script>


<style lang="scss" scoped>
$t-mf: "Arial";
.main {
  .content {
    
      padding-top: 100px;
    background: #ffffff;
    .timg {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .card {
      width: 100%;
      height: 1000px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .box {
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .left {
          width: 100%;
          font-family: $t-mf;
          margin-bottom: 34px;
          .title {
            font-size: 26px;
            line-height: 34px;
            font-weight: bold;
            color: #222222;
            position: relative;
            margin: 39px 0;
            text-align: left;
            padding-left: 20px;
          }
          .title::before {
            position: absolute;
            left: 0;
            height: 25px;
            width: 4px;
            border-radius: 2px;
            content: "";
            background: #ff4576;
            top: 5px;
          }
          .cont {
            font-size: 16px;
            line-height: 28px;
            font-family: $t-mf;
            color: #333333;
            text-align: left;
            .red {
              color: #ff4576;
            }
          }
        }
        .right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .item {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
                height: 100%;
            .isimg {
              height: 311px;
              width: 311px;
              border-radius: 10px;
              margin: 10px 0;
            }
            .ys {
              width: 311px;
              height: 311px;
              margin: 10px 0;
            }
            .sh {
              height: 311px;
              width: 311px;
                 border-radius: 10px;
              margin: 10px 0;
            }
            .txt {
              font-size: 26px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #222222;
              line-height: 34px;
            }
            .red {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ff4576;
              line-height: 34px;
            }
            .btn {
              width: 200px;
              height: 62px;
              background: #ff4576;
              box-shadow: 0px 10px 18px 0px rgba(207, 78, 112, 0.18);
              border-radius: 20px;
              font-size: 24px;
              font-family: $t-mf;
              font-weight: bold;
              color: #ffffff;
              line-height: 62px;
              margin-top: 20px;
              text-align: center;              
              cursor: pointer;
            }
          }
          .cent{
              margin-top: 270px;
          }

          .nex {
            height: 34px;
            width: 50px;
            margin: 20px;
          }
        }
      }
      .changer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 1200px;
        .title{
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #222222;
          line-height: 45px;
        }
        .select{
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #222222;
          line-height: 45px;
          border-bottom: 2px solid #333333;
          display: flex;
          align-items: center;
          margin: 20px 0;
          cursor: pointer;
          img{
            height: 10px;
            width: 18px;
            margin-left: 20px;
          }
        }
        .red{
          color: #FF4576;
          font-size: 14px;
            font-family: Source Han Sans CN;
          font-weight: 500;
        }
      }
    }
    .cardh5{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .left {
          font-family: $t-mf;
          padding: 33px;
          .title {
            font-size: 26px;
            line-height: 34px;
            font-weight: bold;
            color: #222222;
            position: relative;
            margin: 39px 0;
            text-align: left;
            padding-left: 20px;
          }
          .title::before {
            position: absolute;
            left: 0;
            height: 25px;
            width: 4px;
            border-radius: 2px;
            content: "";
            background: #ff4576;
            top: 5px;
          }
          .cont {
            font-size: 16px;
              line-height: 28px;
            font-family: $t-mf;
            color: #333333;
            text-align: left;
            .red {
              color: #ff4576;
            }
          }
        }
        .right {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          width: 100%;
              margin-bottom: 80px;
          .item {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 20px auto;
            .isimg {
              height: 341px;
              width: 341px;
               border-radius: 10px;
              margin: 10px 0;
            }
            .ys {
              width: 251px;
              height: 251px;
            }
            .sh {
              height: 341px;
              width: 341px;
            }
            .txt {
              font-size: 26px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #222222;
              line-height: 34px;
            }
            .red {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ff4576;
              line-height: 34px;
            }
            .btn {
              width: 200px;
              height: 62px;
              background: #ff4576;
              box-shadow: 0px 10px 18px 0px rgba(207, 78, 112, 0.18);
              border-radius: 20px;
              font-size: 24px;
              font-family: $t-mf;
              font-weight: bold;
              color: #ffffff;
              line-height: 62px;
              margin-top: 20px;
              text-align: center;              
              cursor: pointer;
            }
          }

          .nex {
            height: 34px;
            width: 50px;
          }
        }
      }
      .changer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .title{
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #222222;
          line-height: 45px;
        }
        .select{
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #222222;
          line-height: 45px;
          border-bottom: 2px solid #333333;
          display: flex;
          align-items: center;
          margin: 20px 0;
          cursor: pointer;
          img{
            height: 10px;
            width: 18px;
            margin-left: 20px;
          }
        }
        .red{
          color: #FF4576;
          font-size: 14px;
            font-family: Source Han Sans CN;
          font-weight: 500;
        }
      }
    }
  }
  .cast{
       height: 100%;
       width: 100%;
      position: relative;
      margin: 0 auto;
       .sbg{
         height: 100%;
         width: 100%;
       }
       .zzz{
         position: absolute;
         z-index: 9;
         top: 50%;
         left: 50%;
         transform: translate(-50%,-50%);
         font-size: 32px;
font-family: Source Han Sans CN;
font-weight: 500;
color: #FF4576;
line-height: 34px;
       }
     }
     .castbomt{
       margin: 10px auto;
       display: flex;
       justify-content: center;
     .btn{
        width: 200px;
              height: 62px;
              background: #ff4576;
              box-shadow: 0px 10px 18px 0px rgba(207, 78, 112, 0.18);
              border-radius: 20px;
              font-size: 24px;
              font-family: $t-mf;
              font-weight: bold;
              color: #ffffff;
              line-height: 62px;
              margin-top: 20px;
              text-align: center;
              cursor: pointer;
     }
     }

     .popup{
     width: 1200px;
   background: #F65080;
.title{
  height: 54px;
}
   .boxs{
     padding: 19px;
     height: 500px;
     overflow: auto;
     display: flex;
     flex-flow: wrap;

     .item{
       height: 270px;
       width: 270px;
      position: relative;
      margin: 10px;
       .sbg{
          height: 270px;
       width: 270px;
         border-radius: 10px;
       }
       .zzz{
         position: absolute;
         z-index: 9;
         top: 50%;
         left: 50%;
         transform: translate(-50%,-50%);
         font-size: 32px;
font-family: Source Han Sans CN;
font-weight: 500;
color: #FF4576;
line-height: 34px;
    white-space: nowrap;
       }
       .bh {
          position: absolute;
          bottom: 10px;
          left: 50%;
          min-width: 100px;
          height: 30px;
          background: #000000;
          opacity: 0.7;
          border-radius: 3px;
          transform: translateX(-50%);
          line-height: 30px;
          color: #ffffff;
          font-size: 18px;
          white-space: nowrap;
          font-family: Source Han Sans CN;
        }
     }
   }
    .bottom{
      background: #ffffff;
      height: 131px;
      .btn{
          width: 280px;
            height: 62px;
            background: #ff4576;
            box-shadow: 0px 10px 18px 0px rgba(207, 78, 112, 0.18);
            border-radius: 20px;
            font-size: 24px;
            font-family: $t-mf;
            font-weight: bold;
            color: #ffffff;
            line-height: 62px;
            margin-top: 38px;
            cursor: pointer;
            border: none;
      }
    }
  }
  .popuph5{
  width: 686px;
  .boxs{
     padding: 29px 19px;
  .item{
    height: 196px;
       width: 196px;
       margin: 10px;
         .sbg{
         height: 196px;
       width: 196px;
         border-radius: 10px;
       }
  }
  }
}
     
}
</style>